import Grid from '@mui/material/Grid'

type GridItem = React.ReactNode | JSX.Element

interface TwoColumnLayoutProps {
  gridItemOne?: GridItem
  gridItemTwo: GridItem
  fullHeight?: boolean
}

/**
 *
 * @description A simple layout with two equal columns on desktop. One column on mobile.
 * @param {TwoColumnLayoutProps} TwoColumnLayoutProps
 *
 */
const TwoColumnLayout = ({
  gridItemOne,
  gridItemTwo,
  fullHeight,
}: TwoColumnLayoutProps) => {
  return (
    <Grid container justifyContent="space-between">
      {/* Column left */}
      {gridItemOne && (
        <Grid item xs={12} md={6}>
          {gridItemOne}
        </Grid>
      )}
      {/* Column Right */}
      <Grid
        item
        xs={12}
        md={6}
        sx={{ minHeight: fullHeight ? 'calc(100vh - 48px - 64px)' : 'inherit' }}
      >
        {gridItemTwo}
      </Grid>
    </Grid>
  )
}

export default TwoColumnLayout
