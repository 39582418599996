import Typography from '@mui/material/Typography'
import Link from 'next/link'
import { NavLinkType } from 'types/styles/navigation'

type FooterNavLinkProps = NavLinkType

const FooterNavLink = ({ label, href }: FooterNavLinkProps) => (
  <Link href={href}>
    <Typography
      variant="subtitle2"
      sx={{
        color: 'common.white',
        textDecoration: 'none',
        textTransform: 'capitalize',
        position: 'relative',
        '&:after': {
          content: "''",
          bottom: 0,
          width: '0%',
          left: 0,
          height: '2px',
          backgroundColor: 'primary.light',
          position: 'absolute',
          transition: '0.3s width ease-in-out',
        },
        '&:active': {
          color: 'primary.dark',
        },
        '&:hover': {
          color: 'primary.light',
          cursor: 'pointer',
          '&:after': {
            width: '100%',
          },
        },
      }}
    >
      {label}
    </Typography>
  </Link>
)

export default FooterNavLink
