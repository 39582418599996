import SearchIcon from '@mui/icons-material/Search'
import {
  Box,
  Divider,
  IconButton,
  InputBase,
  MenuItem,
  Paper,
  Select,
} from '@mui/material'
import * as Sentry from '@sentry/nextjs'
import axios from 'axios'
// import Hints from 'components/layouts/common/Navbar/Search/Hints'
import useSearchStyles from 'components/layouts/common/Navbar/Search/Search.styles'
import {
  searchCategoriesMap,
  selectOptions,
} from 'components/layouts/common/Navbar/Search/utils/searchCategories'
import {
  addHistoryElement,
  //   removeHistoryElement,
} from 'components/layouts/common/Navbar/Search/utils/searchHelpers'
import { useRouter } from 'next/router'
import React, { useEffect, useRef, useState } from 'react'
import capitalize from 'utils/capitalize'

interface SearchProps {
  onSearchResults: (results: []) => void
}

const Search = ({ onSearchResults }: SearchProps) => {
  const styles = useSearchStyles()
  const router = useRouter()
  const { query } = router
  const isSearchPage = router.pathname === '/search'

  //   const [hintsOpen, setHintsOpen] = useState(false)
  const [searchInput, setSearchInput] = useState<string>(
    (query.searchPhrase as string) || ''
  )
  const [searchCategory, setSearchCategory] = useState<string>(
    (query.category as string) || 'all'
  )
  const [searchHistory, setSearchHistory] = useState<string[]>([])

  const search = async (searchPhrase: string, category: string) => {
    const requestCategory = searchCategoriesMap.get(category)

    try {
      const response = await axios.post(
        `${process.env.apiDomain}/${requestCategory}/search`,
        {
          sort: 'newest',
          page_no: '0',
          paginator_count: 100,
          search_key: searchPhrase,
        }
      )
      const { rows } = response.data
      onSearchResults(rows)
    } catch (error) {
      Sentry.captureException(error)
    }

    router.push(`/search?searchPhrase=${searchPhrase}&category=${category}`)
  }

  const submitButtonRef = useRef(null)

  useEffect(() => {
    if (!isSearchPage) {
      setSearchInput('')
      setSearchCategory('all')
    } else {
      setSearchInput((query.searchPhrase as string) || '')
      setSearchCategory((query.category as string) || 'all')
    }
  }, [query, isSearchPage])

  return (
    <Box sx={styles.wrapper}>
      <Paper
        component="form"
        sx={
          // hintsOpen ? styles.paperActive :
          styles.paper
        }
        onSubmit={(e: React.FormEvent) => {
          e.preventDefault()
          search(searchInput, searchCategory)
          setSearchHistory(addHistoryElement(searchInput, searchHistory))
        }}
      >
        <IconButton
          type="submit"
          sx={{ p: '10px' }}
          aria-label="search"
          ref={submitButtonRef}
        >
          <SearchIcon />
        </IconButton>
        <InputBase
          sx={styles.input}
          placeholder="Search"
          value={searchInput}
          e
          onFocus={() => {
            // setHintsOpen(true)
          }}
          //   @ts-ignore FIX-ME
          onChange={(e: React.FormEvent<HTMLInputElement>) => {
            const target = e.target as HTMLInputElement
            // setHintsOpen(true)
            setSearchInput(target.value)
          }}
          //   onBlur={() => setHintsOpen(false)}
        />
        {isSearchPage && (
          <>
            <Divider sx={styles.divider} orientation="vertical" />
            <Select
              sx={styles.select}
              value={searchCategory}
              onChange={(e) => {
                search(searchInput, e.target.value)
              }}
            >
              {selectOptions.map((option) => {
                return (
                  <MenuItem value={option} key={option}>
                    {capitalize(option)}
                  </MenuItem>
                )
              })}
            </Select>
          </>
        )}
      </Paper>

      {/* {hintsOpen && (
        <Hints
          onHintChosen={(hint: string) => {
            setSearchHistory(addHistoryElement(hint, searchHistory))
            setSearch(hint)
            setHintsOpen(false)
          }}
          searchHistory={searchHistory}
          onHistoryElementRemoved={(el: string) =>
            setSearchHistory(removeHistoryElement(el, searchHistory))
          }
        />
      )} */}
    </Box>
  )
}

export default Search
