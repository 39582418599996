const useDesktopFooterStyles = () => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: '40px 0',
      rowGap: '104px',
      color: 'white ',
    },
    callToAction: {
      display: 'flex',
      justifyContent: 'flex-start',
      columnGap: '16px',
    },
    appStoreLogos: {
      display: 'flex',
      columnGap: '16px',
    },
    navContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    links: {
      display: 'flex',
      flexDirection: 'row',
      columnGap: '40px',
    },
    navLinks: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      columnGap: '24px',
      rowGap: '4px',
    },
    socialLinks: {
      display: 'flex',
      columnGap: '24px',
      rowGap: '4px',
      marginLeft: 'auto',
    },
  }
}

export default useDesktopFooterStyles
