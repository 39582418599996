import Button, { ButtonProps } from '@mui/material/Button'
import { ROUTES } from 'constants/navigation'
import Link from 'next/link'

type NavCTAButtonProps = Omit<ButtonProps, 'size | sx'>

const NavCTAButton = ({ ...props }: NavCTAButtonProps) => {
  return (
    <Link href={ROUTES.ARTIST_APPLY} passHref>
      <Button
        size="small"
        variant="outlined"
        color="white"
        {...props}
        sx={{
          '&:hover': {
            color: '#000',
            backgroundColor: '#fff',
            borderColor: '#fff',
          },
        }}
      >
        {props.children}
      </Button>
    </Link>
  )
}

export default NavCTAButton
