import Button, { ButtonProps } from '@mui/material/Button'
import { SxProps, Theme } from '@mui/material/styles'
import { ROUTES } from 'constants/navigation'
import Link from 'next/link'

type FooterCTAButtonProps = Omit<ButtonProps, 'size | sx'>

const styles: SxProps<Theme> = {
  minWidth: '190px',
  height: 'fit-content',
  margin: 'auto 0',
  //   color: 'primary.light',
  //   borderColor: 'primary.light',
  //   '&:hover': {
  //     color: 'common.black',
  //     borderColor: 'primary.light',
  //     backgroundColor: 'primary.light',
  //   },
}

/**
 *
 * @description Call to action button on the footer components.
 * @param {FooterCTAButtonProps} FooterCTAButtonProps
 *
 */
const FooterCTAButton = ({ ...props }: FooterCTAButtonProps) => {
  return (
    <Link href={ROUTES.ARTIST_APPLY} passHref>
      <Button variant="outlined-rounded" color="white" sx={styles} {...props}>
        {props.children}
      </Button>
    </Link>
  )
}

export default FooterCTAButton
