import { ThemeOptions } from '@mui/material/styles'
import breakpoints from 'styles/breakpoints'
import * as MUI from 'styles/components'
import typography from 'styles/typography'

const defaultThemeOptions: ThemeOptions = {
  breakpoints,
  typography,
  components: {
    MuiAccordion: MUI.MuiAccordion,
    MuiAccordionActions: MUI.MuiAccordionActions,
    MuiAccordionDetails: MUI.MuiAccordionDetails,
    MuiAccordionSummary: MUI.MuiAccordionSummary,
    MuiButton: MUI.MuiButton,
    MuiContainer: MUI.MuiContainer,
    MuiCssBaseline: MUI.MuiCssBaseline,
    MuiFormControl: MUI.MuiFormControl,
    MuiFormControlLabel: MUI.MuiFormControlLabel,
    MuiFormGroup: MUI.MuiFormGroup,
    MuiFormHelperText: MUI.MuiFormHelperText,
    MuiFormLabel: MUI.MuiFormLabel,
    MuiInputBase: MUI.MuiInputBase,
    MuiInputLabel: MUI.MuiInputLabel,
    MuiOutlinedInput: MUI.MuiOutlinedInput,
    MuiPaper: MUI.MuiPaper,
    MuiRadio: MUI.MuiRadio,
  },
}

export default defaultThemeOptions
