import { PaletteOptions } from '@mui/material/styles'

import colors from './colors'

/**
 * @description Palette colors for MUI theme
 * @see https://mui.com/material-ui/customization/palette/
 */
const palette: PaletteOptions = {
  primary: {
    lightest: colors.primary[100],
    lighter: colors.primary[200],
    light: colors.primary[300],
    main: colors.primary[500],
    dark: colors.primary[700],
    darker: colors.primary[800],
    darkest: colors.primary[900],
  },
  secondary: {
    lightest: colors.secondary[100],
    lighter: colors.secondary[200],
    light: colors.secondary[300],
    main: colors.secondary[500],
    dark: colors.secondary[700],
    darker: colors.secondary[800],
    darkest: colors.secondary[900],
  },
  grey: colors.grey,
  common: colors.common,
  white: {
    light: colors.common.white,
    main: colors.common.white,
    dark: colors.common.white,
  },
  black: {
    light: colors.common.black,
    main: colors.common.black,
    dark: colors.common.black,
  },
  error: {
    light: colors.error.light,
    main: colors.error.DEFAULT,
    dark: colors.error.dark,
  },
  warning: {
    light: colors.warning.light,
    main: colors.warning.DEFAULT,
    dark: colors.warning.dark,
  },
  success: {
    light: colors.success.light,
    main: colors.success.DEFAULT,
    dark: colors.success.dark,
  },
}

export default palette
