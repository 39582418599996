const useMobileFooterStyles = () => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: '40px 0',
      rowGap: '32px',
    },
    callToAction: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: '16px',
    },
    navLinks: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      columnGap: '24px',
      rowGap: '4px',
    },
    socialLinks: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      columnGap: '24px',
      rowGap: '4px',
    },
  }
}

export default useMobileFooterStyles
