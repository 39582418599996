const useRootLayoutStyles = () => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      background: 'linear-gradient(0deg, #0E1F39 8.29%, #66119F 83.28%)',
    },
    containerSearch: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    },
    main: {
      flex: 1,
    },
  }
}

export default useRootLayoutStyles
