import Box from '@mui/material/Box'
import { Footer } from 'components/layouts/common'
import SearchNavbar from 'components/layouts/common/Navbar/SearchNav'
import { FOOTER_NAV_LINKS } from 'constants/navigation'
import { useRouter } from 'next/router'
import { createContext, useState } from 'react'

import useRootLayoutStyles from './RootLayout.styles'
export const SearchResultsContext = createContext<string[]>(['ewa'])

interface RootLayoutProps {
  children: React.ReactNode
  withScroll?: boolean
}

const RootLayout = (props: RootLayoutProps) => {
  const router = useRouter()
  const styles = useRootLayoutStyles()
  const [results, setResults] = useState([])
  const isSearchPage = router.pathname.includes('search')

  return (
    <SearchResultsContext.Provider value={results}>
      <Box
        id="root-layout"
        sx={isSearchPage ? styles.containerSearch : styles.container}
      >
        {!props.withScroll && (
          <SearchNavbar
            onSearchResults={(searchResults: []) => setResults(searchResults)}
          />
        )}
        <Box component="main" sx={styles.main}>
          {props.children}
        </Box>
        {!props.withScroll && <Footer navLinks={FOOTER_NAV_LINKS} />}
      </Box>
    </SearchResultsContext.Provider>
  )
}

export default RootLayout
