import { Box, Button } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Container from '@mui/material/Container'
import Toolbar from '@mui/material/Toolbar'
import { CompanyLogo } from 'components/icon'
import { NavLink } from 'components/layouts/common/Navbar/common'
import Search from 'components/layouts/common/Navbar/Search/Search'
import useSearchNavStyles from 'components/layouts/common/Navbar/SearchNav.styles'
import Link from 'next/link'
import React from 'react'

interface SearchNavProps {
  onSearchResults: (results: []) => void
}

const SearchNavbar = ({ onSearchResults }: SearchNavProps) => {
  const styles = useSearchNavStyles()
  return (
    <AppBar position="static" sx={styles.appBar} id="nav">
      <CompanyLogo width="105" height="30" />

      <Container component="nav" maxWidth="xl">
        <Toolbar disableGutters sx={styles.toolbar}>
          <Search onSearchResults={onSearchResults} />
          <Box sx={styles.navLink}>
            <NavLink label="For Artists" href="/for-artists" />
          </Box>
          <Box sx={styles.navCta}>
            <Link href="/apply">
              <Button variant="outlined-rounded" color="white">
                join as an artists
              </Button>
            </Link>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
export default SearchNavbar
