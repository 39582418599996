import Box from '@mui/material/Box'
import { AppStoreLogo, CompanyLogo } from 'components/icon'

import { FooterCTAButton, FooterNavLink, FooterSocialLink } from './common'
import { FooterProps } from './Footer.types'
import useMobileFooterStyles from './MobileFooter.styles'

type MobileFooterProps = FooterProps

const MobileFooter = ({ navLinks, socialLinks }: MobileFooterProps) => {
  const styles = useMobileFooterStyles()

  return (
    <Box sx={styles.container}>
      <Box sx={styles.callToAction}>
        <AppStoreLogo store="apple" />
        <AppStoreLogo store="google" />
      </Box>
      <FooterCTAButton fullWidth>Join as an Artist</FooterCTAButton>
      <CompanyLogo black />
      <Box className="footer-mobile-nav-links" sx={styles.navLinks}>
        {navLinks.map(({ label, href }, index) => (
          <FooterNavLink key={index} href={href} label={label} />
        ))}
      </Box>
      <Box className="footer-mobile-social-links" sx={styles.socialLinks}>
        {socialLinks.map(({ icon, href, name }) => (
          <FooterSocialLink key={name} name={name} icon={icon} href={href} />
        ))}
      </Box>
    </Box>
  )
}

export default MobileFooter
