import Typography from '@mui/material/Typography'
import Link from 'next/link'
import { useRouter } from 'next/router'

interface NavLinkProps {
  href: string
  label: string
}

const NavLink = ({ label, href }: NavLinkProps) => {
  const router = useRouter()
  const isActive = router.pathname === href

  return (
    <Link href={href}>
      <a>
        <Typography
          variant="subtitle1"
          component="div"
          sx={{
            textTransform: 'capitalize',
            padding: '0.875em 1em',
            position: 'relative',

            ...(isActive && { color: ' white' }),

            '&:after': {
              content: "''",
              bottom: 0,
              width: isActive ? '100%' : '0%',
              left: 0,
              height: '2px',
              backgroundColor: ' white',
              position: 'absolute',
              transition: '0.3s width ease-in-out',
            },
            '&:active': {
              color: ' white',
            },
            '&:hover': {
              color: ' white',
              ':after': {
                width: '100%',
              },
            },
          }}
        >
          {label}
        </Typography>
      </a>
    </Link>
  )
}

export default NavLink
