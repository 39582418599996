export const removeHistoryElement = (el: string, history: string[]) => {
  const searchHistoryCopy = [...history]
  const removeIndex = history.findIndex((element) => element === el)

  searchHistoryCopy.splice(removeIndex, 1)
  return searchHistoryCopy
}

export const addHistoryElement = (el: string, history: string[]) => {
  const searchHistoryCopy = [...history]
  const elementIndex = history.findIndex((element) => element === el)

  if (elementIndex > -1) {
    searchHistoryCopy.splice(elementIndex, 1)
  }
  searchHistoryCopy.unshift(el)

  return searchHistoryCopy
}
