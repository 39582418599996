import IconButton from '@mui/material/IconButton'
import { useTheme } from '@mui/material/styles'
import { SocialLinkType } from 'types/styles/navigation'

type FooterSocialLinkProps = SocialLinkType

const FooterSocialLink = ({ icon, href, name }: FooterSocialLinkProps) => {
  const { palette } = useTheme()

  const styles = {
    width: 24,
    height: 24,
    padding: 0,
    '& :hover': {
      path: {
        fill: palette.primary.light,
      },
    },
  }

  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
      aria-label={`link to ${name}`}
    >
      <IconButton sx={styles}>{icon}</IconButton>
    </a>
  )
}

export default FooterSocialLink
