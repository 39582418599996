import Box from '@mui/material/Box'
import { AppStoreLogo, CompanyLogo } from 'components/icon'

import { FooterCTAButton, FooterNavLink, FooterSocialLink } from './common'
import useDesktopFooterStyles from './DesktopFooter.styles'
import { FooterProps } from './Footer.types'

type DesktopFooterProps = FooterProps

const DesktopFooter = ({ navLinks, socialLinks }: DesktopFooterProps) => {
  const styles = useDesktopFooterStyles()

  return (
    <Box sx={styles.container}>
      <Box sx={styles.callToAction}>
        <Box sx={styles.appStoreLogos}>
          <AppStoreLogo store="apple" transparent />
          <AppStoreLogo store="google" transparent />
        </Box>
        <FooterCTAButton>Join as an Artist</FooterCTAButton>
      </Box>
      <Box sx={styles.navContainer}>
        <CompanyLogo />
        <Box className="footer-links" sx={styles.links}>
          <Box className="footer-nav-links" sx={styles.navLinks}>
            {navLinks.map(({ label, href }, index) => (
              <FooterNavLink key={index} href={href} label={label} />
            ))}
          </Box>
          <Box className="footer-social-links" sx={styles.socialLinks}>
            {socialLinks.map(({ icon, href, name }) => (
              <FooterSocialLink
                key={name}
                name={name}
                icon={icon}
                href={href}
              />
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default DesktopFooter
