export const HEADER_NAV_LINKS = [
  { label: 'Meet artists', href: '/artists' },
  { label: 'For tattoo lovers', href: '/tattoo-lovers' },
  { label: 'For artists', href: '/for-artists' },
]

export const FOOTER_NAV_LINKS = [
  { label: 'Contact us', href: '/contact' },
  { label: 'FAQ', href: '/faq' },
]

export const ROUTES = {
  HOME: '/',
  ARTISTS: '/artists',
  TATTOO_LOVERS: '/tattoo-lovers',
  FOR_ARTISTS: '/for-artists',
  CONTACT: '/contact',
  FAQ: '/faq',
  ARTIST_APPLY: '/apply',
}
