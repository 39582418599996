import { CommonColors } from '@mui/material/styles/createPalette'

const purple = {
  900: '#0F001A',
  800: '#2E004D',
  700: '#4D0080',
  600: '#6B00B3',
  500: '#8A00E6',
  400: '#A31AFF',
  300: '#B84DFF',
  200: '#CC80FF',
  100: '#E0B3FF',
  50: '#FAF3FE',
}

const java = {
  900: '#07292A',
  800: '#0E5255',
  700: '#147C80',
  600: '#1BA5AB',
  500: '#22CED5',
  400: '#4ED8DE',
  300: '#7AE2E6',
  200: '#A7EBEE',
  100: '#D3F5F6',
  50: '#F2FCFC',
}

const grey = {
  900: '#333333',
  800: '#424242',
  700: '#646464',
  600: '#9B9B9B',
  500: '#D0D0D0',
  400: '#EAEAEA',
  300: '#F3F3F3',
  200: '#F8F8F8',
  100: '#FAFAFA',
  50: '#FCFCFC',
}

const red = {
  dark: '#C30814',
  DEFAULT: '#FF3E30',
  light: '#FFEBEE',
}

const orange = {
  dark: '#F36D0C',
  DEFAULT: '#FFBA68',
  light: '#FFF5E5',
}

const green = {
  dark: '#1D7100',
  DEFAULT: '#87CB29',
  light: '#F1F9E7',
}

export const common: CommonColors = {
  white: '#FFFFFF',
  black: '#000000',
}

export const shadows = {
  image: '6px 12px 12px rgba(0, 0, 0, 0.1)',
}

export const gradients = {
  homepage: 'linear-gradient(0deg, #8C62DB 8.29%, #66119F 44.6%)',
  dark: 'linear-gradient(0deg, #0E1F39 8.29%, #66119F 83.28%)',
  primary: 'linear-gradient(180deg, #9900FF 0%, #66119F)',
  footer: 'linear-gradient(180deg, #000000 0%, #1C002F 94.53%, #3E0167 100%)',
}

const colors = {
  primary: purple,
  secondary: java,
  error: red,
  warning: orange,
  success: green,
  grey,
  common,
}

export const blurDataURL =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNcLPW/CQAFZAJAYAMfpQAAAABJRU5ErkJggg=='

export default colors
