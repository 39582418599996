import Logo from 'assets/icons/logo.svg'
import LogoBlack from 'assets/icons/logo-black.svg'
import Link from 'next/link'

interface CompanyLogoProps {
  width?: string
  height?: string
  black?: boolean
}

const CompanyLogo = ({
  width = '86',
  height = '24',
  black = false,
}: CompanyLogoProps) => (
  <Link href="/">
    <a>
      {black ? (
        <LogoBlack width={width} height={height} />
      ) : (
        <Logo width={width} height={height} />
      )}
    </a>
  </Link>
)

export default CompanyLogo
