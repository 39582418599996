import { Components } from '@mui/material'
import { colors } from 'styles/colors'
import typography from 'styles/typography'

const MuiFormControl: Components['MuiFormControl'] = {
  styleOverrides: {
    root: {
      gap: '10px',
      marginBottom: '24px',
    },
  },
}

const MuiFormControlLabel: Components['MuiFormControlLabel'] = {
  styleOverrides: {
    label: {
      ...typography.subtitle1,
    },
  },
}

const MuiFormGroup: Components['MuiFormGroup'] = {}

const MuiFormHelperText: Components['MuiFormHelperText'] = {
  styleOverrides: {
    root: {
      color: colors.error.DEFAULT,
      fontFamily: '"Lato", sans-serif',
    },
  },
}

const MuiFormLabel: Components['MuiFormLabel'] = {
  styleOverrides: {
    root: {
      ...typography.h6,
      color: colors.common.black,
    },
  },
}

export {
  MuiFormControl,
  MuiFormControlLabel,
  MuiFormGroup,
  MuiFormHelperText,
  MuiFormLabel,
}
