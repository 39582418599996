import { Components } from '@mui/material'
import { colors } from 'styles/colors'
import typography from 'styles/typography'

const MuiAccordion: Components['MuiAccordion'] = {
  defaultProps: {
    square: true,
  },
  styleOverrides: {
    root: {
      boxShadow: 'none',
      borderBottom: `1px solid ${colors.primary[500]}`,
    },
  },
}

const MuiAccordionActions: Components['MuiAccordionActions'] = {
  styleOverrides: {},
}

const MuiAccordionDetails: Components['MuiAccordionDetails'] = {
  styleOverrides: {
    root: {
      ...typography.caption,
      ['@media (min-width:900px)']: {
        ...typography.subtitle1,
      },
    },
  },
}

const MuiAccordionSummary: Components['MuiAccordionSummary'] = {
  styleOverrides: {
    root: {
      ...typography.subtitle2,

      ['@media (min-width:900px)']: {
        ...typography.h6,
      },
    },
    content: {
      margin: '40px 0 36px',

      '&.Mui-expanded': {
        color: colors.primary[500],
      },

      ['@media (min-width:900px)']: {
        margin: '42px 0 36px',
      },
    },
  },
}

export {
  MuiAccordion,
  MuiAccordionActions,
  MuiAccordionDetails,
  MuiAccordionSummary,
}
