import { Box, Container } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import FacebookIcon from 'assets/icons/social-facebook.svg'
import InstagramIcon from 'assets/icons/social-instagram.svg'
import LinkedinIcon from 'assets/icons/social-linkedin.svg'
import {
  FACEBOOK_PROFILE_LINK,
  INSTAGRAM_PROFILE_LINK,
  LINKEDIN_PROFILE_LINK,
} from 'constants/company'

import DesktopFooter from './DesktopFooter'
import { BaseFooterProps } from './Footer.types'
import MobileFooter from './MobileFooter'

const socialLinks = [
  { name: 'instagram', icon: <InstagramIcon />, href: INSTAGRAM_PROFILE_LINK },
  { name: 'facebook', icon: <FacebookIcon />, href: FACEBOOK_PROFILE_LINK },
  { name: 'linkedin', icon: <LinkedinIcon />, href: LINKEDIN_PROFILE_LINK },
]

const Footer = ({ navLinks }: BaseFooterProps) => {
  const { breakpoints } = useTheme()
  const isMediumScreen = useMediaQuery(breakpoints.up('md'), {
    noSsr: true,
  })

  return (
    <Box component="footer">
      <Container maxWidth="xl">
        {isMediumScreen ? (
          <DesktopFooter navLinks={navLinks} socialLinks={socialLinks} />
        ) : (
          <MobileFooter navLinks={navLinks} socialLinks={socialLinks} />
        )}
      </Container>
    </Box>
  )
}

export default Footer
