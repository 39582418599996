import IconButton from '@mui/material/IconButton'
import AppleStoreLogo from 'assets/app-store/apple.svg'
import AppleStoreDarkLogo from 'assets/app-store/apple-dark.svg'
import AppleStoreTransparentLogo from 'assets/app-store/apple-transparent.svg'
import GoogleStoreLogo from 'assets/app-store/google.svg'
import GoogleStoreDarkLogo from 'assets/app-store/google-dark.svg'
import GoogleStoreTransparentLogo from 'assets/app-store/google-transparent.svg'
import { APP_LINK_APPLE, APP_LINK_GOOGLE } from 'constants/company'

type Store = 'apple' | 'google'

interface AppStoreLogoProps {
  store: Store
  small?: boolean
  dark?: boolean
  transparent?: boolean
}

const getStoreLogo = (dark: boolean, store: Store, transparent: boolean) => {
  if (dark) {
    if (store === 'apple') {
      return <AppleStoreDarkLogo width="100%" height="100%" />
    } else {
      return <GoogleStoreDarkLogo width="100%" height="100%" />
    }
  } else if (transparent) {
    if (store === 'apple') {
      return <AppleStoreTransparentLogo width="100%" height="100%" />
    } else {
      return <GoogleStoreTransparentLogo width="100%" height="100%" />
    }
  } else {
    if (store === 'apple') {
      return <AppleStoreLogo width="100%" height="100%" />
    } else {
      return <GoogleStoreLogo width="100%" height="100%" />
    }
  }
}

const getStoreUrl = (store: Store) => {
  switch (store) {
    case 'apple':
      return APP_LINK_APPLE
    case 'google':
      return APP_LINK_GOOGLE
    default:
      return ''
  }
}

const AppStoreLogo = ({
  store,
  small,
  dark = false,
  transparent = false,
}: AppStoreLogoProps) => {
  const storeLogo = getStoreLogo(dark, store, transparent)
  const storeUrl = getStoreUrl(store)

  return (
    <a
      href={storeUrl}
      target="_blank"
      rel="noreferrer"
      aria-label={`${store} store link`}
    >
      <IconButton
        sx={{
          borderRadius: '4px',
          height: small ? '45px' : '56px',
          width: 'max-content',
          maxWidth: '166px',
          transition: '0.3s all ease-in-out',
          p: 0,
          'svg [class*="text"]': {
            transition: '0.3s all ease-in-out',
            fill: dark ? '#000' : '#fff',
          },
          'svg [class*="bg"]': {
            transition: '0.3s all ease-in-out',
            fill: dark ? '#fff' : transparent ? 'transparent' : '#000',
          },
          '&:hover': {
            'svg [class*="text"]': {
              fill: dark ? '#fff' : '#000',
            },
            'svg [class*="bg"]': {
              fill: dark ? '#000' : '#fff',
            },
          },
        }}
      >
        {storeLogo}
      </IconButton>
    </a>
  )
}

export default AppStoreLogo
