import 'styles/globals.css'

import { CacheProvider, EmotionCache } from '@emotion/react'
import CssBaseline from '@mui/material/CssBaseline'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { RootLayout } from 'components/layouts'
// import ScrollLayout from 'components/layouts/ScrollLayout/ScrollLayout'
import type { AppProps as NextAppProps } from 'next/app'
import Head from 'next/head'
import { NextIntlProvider } from 'next-intl'
import lightThemeOptions from 'styles/theme/lightThemeOptions'
import createEmotionCache from 'utils/createEmotionCache'

interface AppProps extends NextAppProps {
  emotionCache?: EmotionCache
}

const clientSideEmotionCache = createEmotionCache()

const lightTheme = createTheme(lightThemeOptions)

const App = (props: AppProps) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="initial-scale = 1.0, maximum-scale = 1.0, minimum-scale = 1.0"
        />
      </Head>
      <CacheProvider value={emotionCache}>
        <ThemeProvider theme={lightTheme}>
          <NextIntlProvider messages={pageProps.messages} locale="en">
            <CssBaseline />
            {/* {pageProps.withScroll ? ( */}
            {/* <ScrollLayout>
              <Component {...pageProps} />
            </ScrollLayout> */}
            {/*} ) : ( */}
            <RootLayout withScroll={pageProps.withScroll}>
              <Component {...pageProps} />
            </RootLayout>
            {/* )} */}
          </NextIntlProvider>
        </ThemeProvider>
      </CacheProvider>
    </>
  )
}

export default App
