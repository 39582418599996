import { Components } from '@mui/material'

const MuiPaper: Components['MuiPaper'] = {
  styleOverrides: {
    root: {
      '&.MuiAccordion-root:before': {
        content: 'none',
      },
    },
  },
}

export default MuiPaper
