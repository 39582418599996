import { Components } from '@mui/material/styles'
import breakpoints from 'styles/breakpoints'

const MuiContainer: Components['MuiContainer'] = {
  styleOverrides: {
    root: {
      // min-width: 0px;
      [`@media (min-width: ${breakpoints.values.xs}px)`]: {
        paddingLeft: '16px',
        paddingRight: '16px',
      },
      // min-width: 600px;
      [`@media (min-width: ${breakpoints.values.sm}px)`]: {
        paddingLeft: '24px',
        paddingRight: '24px',
      },
      // min-width: 900px;
      [`@media (min-width: ${breakpoints.values.md}px)`]: {
        paddingLeft: '40px',
        paddingRight: '40px',
      },
      // min-width: 1200px;
      [`@media (min-width: ${breakpoints.values.lg}px)`]: {
        paddingLeft: '80px',
        paddingRight: '80px',
      },
      // min-width: 1536px;
      [`@media (min-width: ${breakpoints.values.xl}px)`]: {
        paddingLeft: '80px',
        paddingRight: '80px',
      },
    },
  },
}

export default MuiContainer
