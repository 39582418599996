import { Components } from '@mui/material'
import { colors } from 'styles/colors'

// // FIXME: Button not adopting extended color props
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    clipped: true
    gradient: true
    'text-icon': true
    'outlined-rounded': true
    'filled-rounded': true
  }
  interface ButtonPropsColorOverrides {
    white: true
    black: true
    purple: true
  }
}

const MuiButton: Components['MuiButton'] = {
  variants: [
    {
      props: { variant: 'filled-rounded', color: 'purple' },
      style: {
        backgroundColor: '#6B00B3',
        borderRadius: '24px',
        fontSize: '14px',
        fontWeight: 800,
        color: 'white',
        '&:hover': {
          backgroundColor: 'white',
          borderColor: colors.common.white,
          color: colors.common.black,
        },
        '&:focus': {
          backgroundColor: 'white',
          borderColor: colors.common.white,
          color: colors.common.black,
        },
        '&:disabled': {
          backgroundColor: 'transparent',
          borderColor: colors.grey[500],
          color: colors.grey[500],
        },
      },
    },
    {
      props: { variant: 'filled-rounded', color: 'white' },
      style: {
        backgroundColor: 'white',
        border: '1px solid white',
        borderRadius: '24px',
        fontSize: '12px',
        fontWeight: 700,
        color: '#591490',
        '&:hover': {
          backgroundColor: 'white',
          borderColor: colors.common.white,
          color: colors.common.black,
        },
        '&:focus': {
          backgroundColor: 'white',
          borderColor: colors.common.white,
          color: colors.common.black,
        },
        '&:disabled': {
          backgroundColor: 'transparent',
          borderColor: colors.grey[500],
          color: colors.grey[500],
        },
      },
    },
    {
      props: { variant: 'outlined-rounded', color: 'white' },
      style: {
        backgroundColor: 'transparent',
        border: '1px solid white',
        borderRadius: '24px',
        fontSize: '12px',
        fontWeight: 600,
        color: colors.common.white,
        '&:hover': {
          backgroundColor: 'white',
          borderColor: colors.common.white,
          color: colors.common.black,
        },
        '&:focus': {
          backgroundColor: 'white',
          borderColor: colors.common.white,
          color: colors.common.black,
        },
        '&:disabled': {
          backgroundColor: 'transparent',
          borderColor: colors.grey[500],
          color: colors.grey[500],
        },
      },
    },
    {
      props: { variant: 'outlined-rounded', color: 'black' },
      style: {
        backgroundColor: 'transparent',
        border: '1px solid black',
        borderRadius: '24px',
        fontSize: '12px',
        fontWeight: 600,
        color: colors.common.black,
        '&:hover': {
          backgroundColor: 'black',
          borderColor: colors.common.black,
          color: colors.common.white,
        },
        '&:focus': {
          backgroundColor: 'black',
          borderColor: colors.common.black,
          color: colors.common.white,
        },
        '&:disabled': {
          backgroundColor: 'transparent',
          borderColor: colors.grey[500],
          color: colors.grey[500],
        },
      },
    },
    {
      props: { variant: 'outlined-rounded', color: 'purple' },
      style: {
        backgroundColor: 'transparent',
        border: '1px solid #8C62DB',
        borderRadius: '24px',
        fontSize: '12px',
        fontWeight: 600,
        color: '#8C62DB',
        '&:hover': {
          backgroundColor: '#8C62DB',
          borderColor: '#8C62DB',
          color: colors.common.white,
        },
        '&:focus': {
          backgroundColor: '#8C62DB',
          borderColor: '#8C62DB',
          color: colors.common.white,
        },
        '&:disabled': {
          backgroundColor: 'transparent',
          borderColor: colors.grey[500],
          color: colors.grey[500],
        },
      },
    },
    {
      props: { variant: 'clipped' },
      style: {
        clipPath: 'polygon(6% 0, 100% 0, 100% 84%, 94% 100%, 0 100%, 0 16%)',
        backgroundColor: colors.primary[500],
        color: colors.common.white,
        '&:hover': {
          backgroundColor: colors.primary[400],
        },
        '&:focus': {
          backgroundColor: colors.primary[600],
        },
        '&:disabled': {
          // Same as default
          color: 'rgba(0, 0, 0, 0.26)',
          boxShadow: 'none',
          backgroundColor: 'rgba(0, 0, 0, 0.12)',
        },
      },
    },
    {
      props: { variant: 'clipped', color: 'primary' },
      style: {
        clipPath: 'polygon(6% 0, 100% 0, 100% 84%, 94% 100%, 0 100%, 0 16%)',
        backgroundColor: colors.primary[500],
        color: colors.common.white,
        '&:hover': {
          backgroundColor: colors.primary[400],
        },
        '&:focus': {
          backgroundColor: colors.primary[600],
        },
        '&:disabled': {
          // Same as default
          color: 'rgba(0, 0, 0, 0.26)',
          boxShadow: 'none',
          backgroundColor: 'rgba(0, 0, 0, 0.12)',
        },
      },
    },
    {
      props: { variant: 'clipped', color: 'secondary' },
      style: {
        clipPath: 'polygon(6% 0, 100% 0, 100% 84%, 94% 100%, 0 100%, 0 16%)',
        backgroundColor: colors.common.white,
        color: colors.primary[500],
        '&:hover': {
          backgroundColor: colors.grey[500],
        },
        '&:focus': {
          backgroundColor: colors.grey[600],
        },
        '&:disabled': {
          // Same as default
          color: 'rgba(0, 0, 0, 0.26)',
          boxShadow: 'none',
          backgroundColor: 'rgba(0, 0, 0, 0.12)',
        },
      },
    },
    {
      props: { variant: 'outlined', color: 'white' },
      style: {
        backgroundColor: 'transparent',
        borderColor: colors.common.white,
        color: colors.common.white,
        '&:hover': {
          borderColor: colors.grey[500],
          color: colors.grey[500],
        },
        '&:focus': {
          borderColor: colors.grey[600],
          color: colors.grey[600],
        },
        '&:disabled': {
          borderColor: colors.grey[800],
          color: colors.grey[800],
        },
      },
    },
    {
      props: { variant: 'outlined', color: 'black' },
      style: {
        backgroundColor: 'transparent',
        borderColor: colors.common.black,
        color: colors.common.black,
        '&:hover': {
          backgroundColor: 'transparent',
          borderColor: colors.grey[800],
          color: colors.grey[800],
        },
        '&:focus': {
          backgroundColor: 'transparent',
          borderColor: colors.grey[600],
          color: colors.grey[600],
        },
        '&:disabled': {
          backgroundColor: 'transparent',
          borderColor: colors.grey[500],
          color: colors.grey[500],
        },
      },
    },
    {
      props: { variant: 'contained', color: 'black' },
      style: {
        backgroundColor: colors.common.black,
        border: '2px solid',
        borderColor: colors.common.black,
        color: colors.common.white,
        '&:hover': {
          backgroundColor: 'transparent',
          border: '2px solid',
          borderColor: colors.common.black,
          color: colors.common.black,
        },
        '&:focus': {
          backgroundColor: 'transparent',
          border: '2px solid',
          borderColor: colors.common.black,
          color: colors.common.black,
        },
      },
    },
    {
      props: { variant: 'gradient' },
      style: {
        backgroundColor: '#6701AB',
        color: colors.common.white,
        maskImage:
          'gradient(linear, left top, left bottom, from(rgba(196, 109, 255, 0.6)), to(rgba(196, 109, 255, 1)))',
        WebkitMaskImage:
          '-webkit-gradient(linear, left top, left bottom, from(rgba(196, 109, 255, 0.6)), to(rgba(196, 109, 255, 1)))',

        '&:hover': {
          backgroundColor: 'black',
          color: colors.common.white,
          '-webkit-mask-image': '',
          maskImage: 'none',
        },
        '&:focus': {
          backgroundColor: 'black',

          color: colors.common.white,
        },
      },
    },
    {
      props: { variant: 'text-icon' },
      style: {
        color: colors.common.black,
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        '&:hover': {
          background: 'none',
          color: '#A31AFF',
          svg: {
            path: {
              fill: '#A31AFF',
            },
          },
        },
        '&:focus': {
          background: 'none',
          color: '#A31AFF',
          svg: {
            path: {
              fill: '#A31AFF',
            },
          },
        },
        '&:disabled': {
          color: 'rgba(0, 0, 0, 0.26)',
          svg: {
            path: {
              fill: 'rgba(0, 0, 0, 0.26)',
            },
          },
        },
      },
    },
  ],
  defaultProps: {
    variant: 'contained',
    disableElevation: true,
  },
  styleOverrides: {
    root: {
      borderRadius: 0,
      maxHeight: 56,
      padding: '1.56em 1.5em 1.31em',
      fontSize: '0.875rem',
      fontWeight: 800,
      lineHeight: '1.125em',
      letterSpacing: '0.03em',
    },
    sizeSmall: {
      maxHeight: 32,
      fontSize: '0.75rem',
    },
    outlined: {
      border: `2px solid ${colors.primary[500]}`,
      '&:hover': {
        border: `2px solid ${colors.primary[400]}`,
      },
      '&:focus': {
        border: `2px solid ${colors.primary[600]}`,
      },
      '&:disabled': {
        borderWidth: 2,
      },
    },
    outlinedPrimary: {
      border: `2px solid ${colors.primary[500]}`,
      '&:hover': {
        border: `2px solid ${colors.primary[400]}`,
      },
      '&:focus': {
        border: `2px solid ${colors.primary[600]}`,
      },
      '&:disabled': {
        borderWidth: 2,
      },
    },
    contained: {
      backgroundColor: colors.primary[500],
      color: colors.common.white,
      '&:hover': {
        backgroundColor: colors.primary[400],
      },
      '&:focus': {
        backgroundColor: colors.primary[600],
      },
    },
    containedPrimary: {
      backgroundColor: colors.primary[500],
      color: colors.common.white,
      '&:hover': {
        backgroundColor: colors.primary[400],
      },
      '&:focus': {
        backgroundColor: colors.primary[600],
      },
    },
  },
}

export default MuiButton
