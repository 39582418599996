const useSearchNavStyles = () => {
  return {
    appBar: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '32px 42px',
    },
    toolbar: { display: 'flex', gap: '2rem' },
    navLink: { minWidth: '140px' },
    navCta: { minWidth: '190px' },
  }
}

export default useSearchNavStyles
