import { Components } from '@mui/material'
import typography from 'styles/typography'

const MuiInputBase: Components['MuiInputBase'] = {
  styleOverrides: {
    root: {
      ...typography.bodyLato1,
    },
  },
}

const MuiOutlinedInput: Components['MuiOutlinedInput'] = {
  styleOverrides: {
    root: {
      borderRadius: '20px',
    },
  },
}

const MuiInputLabel: Components['MuiInputLabel'] = {
  styleOverrides: {
    root: {
      ...typography.bodyLato1,
    },
  },
}

const MuiRadio: Components['MuiRadio'] = {
  styleOverrides: {
    root: {
      padding: '10px',
    },
  },
}

export { MuiInputBase, MuiInputLabel, MuiOutlinedInput, MuiRadio }
