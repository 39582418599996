import { ThemeOptions } from '@mui/material/styles'
import { palette } from 'styles/colors'

import defaultThemeOptions from './defaultThemeOptions'

const lightThemeOptions: ThemeOptions = {
  ...defaultThemeOptions,
  palette: {
    mode: 'light',
    ...palette,
  },
}

export default lightThemeOptions
