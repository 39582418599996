const capitalize = (text: string) => {
  let capitalizedText = ''

  if (!text) {
    return
  }

  capitalizedText = text[0].toUpperCase() + text.slice(1)

  return capitalizedText
}

export default capitalize
