import { TypographyOptions } from '@mui/material/styles/createTypography'

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    buttonBrand: true
    buttonPrimary: true
    buttonSecondary: true
    bodyLato1: true
    bodyLato2: true
    link: true
  }
}
interface ExtendedTypographyOptions extends TypographyOptions {
  buttonBrand: React.CSSProperties
  buttonPrimary: React.CSSProperties
  buttonSecondary: React.CSSProperties
  bodyLato1: React.CSSProperties
  bodyLato2: React.CSSProperties
  link: React.CSSProperties
}

const typography: ExtendedTypographyOptions = {
  fontFamily: ['Spartan', 'Lato', 'sans-serif'].join(','),
  h1: {
    fontFamily: "'Spartan', sans-serif",
    fontSize: '6.5rem',
    fontWeight: 600,
    lineHeight: 'auto',
    letterSpacing: '-1.5px',
  },
  h2: {
    fontFamily: "'Spartan', sans-serif",
    fontSize: '4.125rem',
    fontWeight: 700,
    lineHeight: 'auto',
    letterSpacing: '-1px',
  },
  h3: {
    fontFamily: "'Spartan', sans-serif",
    fontSize: '3.5rem',
    fontWeight: 700,
    lineHeight: 1.4,
    letterSpacing: '-1px',
  },
  h4: {
    fontFamily: "'Spartan', sans-serif",
    fontSize: '2.5rem',
    fontWeight: 700,
    lineHeight: 1.45,
    letterSpacing: '-1px',
  },
  h5: {
    fontFamily: "'Spartan', sans-serif",
    fontSize: '2rem',
    fontWeight: 700,
    lineHeight: 1.3,
    letterSpacing: '-1px',
  },
  h6: {
    fontFamily: "'Spartan', sans-serif",
    fontSize: '1.5rem',
    fontWeight: 700,
    lineHeight: 1.3,
    letterSpacing: '-1%',
  },
  subtitle1: {
    fontFamily: "'Lato', sans-serif",
    fontSize: '1.25rem',
    fontWeight: 400,
    lineHeight: 1.5,
  },
  subtitle2: {
    fontFamily: "'Spartan', sans-serif",
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: 1.7,
    letterSpacing: 0,
  },
  body1: {
    fontFamily: "'Spartan', sans-serif",
    fontSize: '0.875rem',
    fontWeight: 500,
    lineHeight: 1.4,
    letterSpacing: '0.25px',
  },
  body2: {
    fontFamily: "'Spartan', sans-serif",
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: 1.6,
    letterSpacing: '0.25px',
  },
  bodyLato1: {
    fontFamily: "'Lato', sans-serif",
    fontSize: '1.125rem',
    fontWeight: 400,
    lineHeight: 1.5,
  },
  bodyLato2: {
    fontFamily: "'Lato', sans-serif",
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: 1.5,
  },
  caption: {
    fontFamily: "'Spartan', sans-serif",
    fontSize: '0.625rem',
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: 0,
  },
  overline: {
    fontFamily: "'Lato', sans-serif",
    fontSize: '0.75rem',
    fontWeight: 700,
    lineHeight: 'auto',
    letterSpacing: '1%',
  },
  link: {
    fontFamily: "'Lato', sans-serif",
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: 'auto',
    textDecoration: 'underline',
  },
  buttonBrand: {
    fontFamily: "'Spartan', sans-serif",
    fontSize: '1rem',
    fontWeight: 800,
    letterSpacing: '3%',
    lineHeight: 'auto',
    textTransform: 'uppercase',
  },
  buttonPrimary: {
    fontFamily: "'Spartan', sans-serif",
    fontSize: '0.875rem',
    fontWeight: 800,
    letterSpacing: '3%',
    lineHeight: 'auto',
    textTransform: 'uppercase',
  },
  buttonSecondary: {
    fontFamily: "'Spartan', sans-serif",
    fontSize: '0.75rem',
    fontWeight: 800,
    letterSpacing: '3%',
    lineHeight: 'auto',
    textTransform: 'uppercase',
  },
}

export default typography
