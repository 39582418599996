const useSearchStyles = () => {
  return {
    wrapper: { position: 'relative', width: '100%' },
    paper: {
      p: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      borderRadius: '30px',
      height: '3.5rem',
    },
    paperActive: {
      p: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      borderRadius: '30px',
      height: '3.5rem',
    },
    input: { ml: 1, flex: 1 },
    select: {
      width: '100px',
      '	.MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },
    divider: { height: 28, m: 0.5 },
  }
}

export default useSearchStyles
